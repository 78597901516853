<template>
<CModal :title="'Billing Number: #' + row.client_billing_no" :show.sync="modal" color="info" size="xl">
    <form @submit.prevent="submit">
        <!-- <div class="row justify-content-between">
         
           
        </div> -->

        <CRow>
            <div class="col-3">
                <label>
                    Payment Date <span class="text-danger">*</span>
                </label>
                <Datepicker input-class="form-control bg-white" v-model="data_params.payment_date" placeholder="" :typeable="true" required>
                </Datepicker>
            </div>
            <div class="col-3">
                <label>
                    Payment Method <span class="text-danger">*</span>
                </label>
                <CSelect v-model="data_params.payment_method" aria-label="Default select example" :options="[
                    { label: 'Cash', value: 'Cash' },
                    { label: 'Bank', value: 'Bank' },
                    { label: 'Cheque', value: 'Cheque' },
                ]">
                </CSelect>
           </div>
           <div class="col-3">
                <label>
                    Reference No.
                </label>
                <CInput type="text" placeholder="Reference No." aria-label="default input example"
                    v-model="data_params.reference_no"/>
           </div>
           <div class="col-3">
                <label>
                    Payment <span class="text-danger">*</span>
                </label>
                <CInput type="text" placeholder="Enter amount" aria-label="default input example"
                    v-model="data_params.amount"  @keypress="isNumber($event)" @keyup="amountPress" required/>
                <div class="" style="margin-top: -13px !important; color:rgb(229, 84, 84)" v-if="payment_validation">{{error_msg}}</div>
                    
          </div>
        </CRow>
        <div slot="footer" class="w-100">
            <CButton type="submit" style="border-radius: .2rem; color: white;" color="info"
                class="ml-1 mr-1 float-right btn-sm"
                :disabled="balance_amount <= 0">
                Save
            </CButton>
            <CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right btn-sm" @click="closeModal">
                Cancel
            </CButton>
        </div>
        <br>
    </form>
    <hr>
    <CRow>
            <div class="col-8">
                <label style="font-size: 16px;">
                    Original Amount
                </label>
                <h3><strong>{{ row.total_amount | CurrencyFormatWithSign }}</strong></h3>
            </div>
            <div class="col-4">
                <label style="font-size: 16px;">
                    Balance Amount
                </label>
                <h3><strong>{{balance_amount|CurrencyFormatWithSign}}</strong></h3>
            </div>
    
    </CRow>
    <br>
     <CRow>
        <CCol lg="12">
            <div>
                <h6>Payment History</h6>
                <CDataTable
                    :loading="is_loading"
                    :tableFilter='{ placeholder: "Search" }'
                    pagination
                    :items="option_data.data"
                    :fields="columns"
                    hover
                    size="sm"
                    :itemsPerPage="10"
                    itemsPerPageSelect
                >  
                <template #created_at="{ item }">
                    <td>
                        {{ item.created_at|dateFormat}}
                    </td>
                </template>
                <template #amount="{ item }">
                    <td >
                        -{{item.amount|CurrencyFormat}}
                    </td>
                </template>
                <template #balance="{ item }">
                    <td>
                        {{item.balance|CurrencyFormat}}
                    </td>
                </template>
                </CDataTable> 
            </div>
        </CCol>
    </CRow>
    <div slot="footer" class="w-100">
    </div>
</CModal>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import config from "../../config.js";
import axios from "../../axios";
import Swal from "sweetalert2";
import moment from "moment";
import _ from 'lodash';
export default {
    components: {
        Datepicker,
    },
    props: {
        showViewModal: { require: true },
        currentPage: { require: true },
        row: { require: true },
    },
    data() {
        return {
            columns: [
                {
                    key: 'payment_date',
                    label: 'Payment Date',
                },
               
                {
                    key: 'payment_method',
                    label: 'Payment Method',
                },
                {
                    key: 'reference_no',
                    label: 'Reference No.',
                },
                {
                    key: 'amount',
                    label: 'Amount',
                },
                {
                    key: 'balance',
                    label: 'Balance',
                },
            ],
            modal: false,
            data_params: {
                payment_date: '',
                amount: null,
                payment_method: 'Cash',
                reference_no: '',
            },
            option_data: [],
            is_loading: false,
            current_page: 1, 
            balance_amount: 0,
            error:'',
            error_msg:'',
            errors:'',
            payment_validation:false,
        }
    },
    methods: {
        getData(){  
            this.is_loading=true;
            axios.get(config.api_path+'/client-billing-payment-receive?client_billing_id='+this.row.id+'&page='+this.current_page+'&limit=5')
            .then(response => {
                this.option_data = response.data;
                this.balance_amount = response.data.balance_amount;
            })
            .catch(err => {
                this.is_loading = false;
            })
            .finally(fin => {
                this.is_loading = false;	
            })
        },
        updatePage(data){
			this.current_page = data;
			this.getData();
		}, 
        submit() {
            Swal.fire({
                title: 'Saving',
                html: 'Please wait...',
                allowOutsideClick:false,
                allowEscapeKey:false,
            });
            Swal.showLoading();
            var formData = new FormData();
            formData.append('client_billing_id', this.row.id);
            formData.append('created_by_id', config.getUserDetail('id'));
            formData.append('amount', this.data_params.amount);
            formData.append('reference_no', this.data_params.reference_no);
            formData.append("payment_method", this.data_params.payment_method);
            formData.append("payment_date", moment(this.data_params.payment_date).format("YYYY-MM-DD"));
            formData.append("balance_amount", this.balance_amount);
            axios.post(config.api_path + '/client-billing-payment-receive', formData)
            .then(response => {
                this.payment_validation = false;
                this.error_msg = '';
                this.$emit('updatePage', this.currentPage);
                Swal.close();
                this.getData();
                this.is_loading = false;
                this.clearData();
                Toast.fire({
                    icon: 'success',
                    title: 'New payment added.'
                })
                
            })
            .catch(error => {
                Swal.close();
                let errors = error.response.data.errors;
                if(errors.msg[0]){
                    this.payment_validation = true;
                    this.error_msg = errors.msg[0];
                }
                this.is_loading = false;
            });
        },
        clearData(){
            this.data_params.payment_date = '';
            this.data_params.amount = null,
            this.data_params.payment_method = 'Cash';
            this.data_params.reference_no = '';
        },
        amountPress(e){
            console.log(e);
        },
        closeModal(){
            this.modal = false;
            this.$emit('modalShowHide', false)
        }
    },
    watch: {
        showViewModal: function () {
            if (this.showViewModal) {
                this.modal = this.showViewModal;
            }
        },
        modal: function () {
            if (!this.modal) {
                this.$emit('modalShowHide', false)
            }
        },
        row: function () {
            if(this.row.id){
                this.getData();
            }
        }
    },
}
</script>

<style lang="scss" scoped>
table.dataTable thead tr {
    background-color: green;
  }
</style>