<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Client Billing ({{dataList.total}})</h5>
			</CCol>
	     		 <CCol lg="6">
					<CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
						:aria-expanded="showCollapse ? 'true' : 'false'"
						aria-controls="collapse1"
						@click="showCollapse = !showCollapse"
						color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('client billing').create"
						size="sm" style="margin-right: 10px" 
						color="success" href="#/main/create_client_billing" target="_blank"
						class="float-lg-right"><i class="fa fa-plus"></i>Create Client Billing
					</CButton>
					 <CButton 
						v-if="config.getPermission('client billing').download"
						size="sm" style="margin-right: 10px" 
						color="primary" @click="download" 
						class="float-lg-right"><i class="fa fa-download"></i>Download
					</CButton>
			    </CCol>
		</CRow>
		<CRow> 
			<CCol lg="12">
				<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
					<CCard class="p-4">
						<Search @depot-search-query="search"/>
					</CCard>
					</CCollapse> 
			</CCol>
		</CRow> 
		<hr>
		<CRow>	
		 <CCol lg="12" >  
			<div class="table-responsive-sm table-responsive-md">
	      		<CDataTable
				  :loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					outlined
					striped
			    >  
				<template #status="{item}">
					<td style="padding: 5px; width: 100px;">
						<CBadge style="margin-top:10px; text-transform:uppercase;" :color="getBadge(item.status)">{{item.status}}</CBadge>
						<span class="label-status" :style="{'color': labelStatusColor(item)}">{{labelStatus(item)}}</span>
					</td>
				</template>
				<template #total_amount="{item}">
					<td >
						{{ item.total_amount }}

					</td>
				</template>
			    	<template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
				            	<CDropdownItem v-if="config.getPermission('client billing').view" @click="viewModal(item)"> <i class="fa fa-eye" > View </i></CDropdownItem>
								<CDropdownItem @click="sendReminder(item)" v-if="item.status != 'PAID' && item.status != 'PARTIALLY PAID' && is_marvel()"> <i class="fa fa-bell"> Send Reminder</i></CDropdownItem>
				            	<CDropdownItem v-if="is_marvel()" @click="modalShowHide(true, item)"> <i class="fa fa-money"> Receive Payment</i></CDropdownItem>
				            	<CDropdownItem v-if="config.getPermission('client billing').update && showUpdate(item)" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Update </i></CDropdownItem>   
				            	<CDropdownItem v-if="config.getPermission('client billing').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem>   
				            </CDropdown>
				        </td>
			      	</template> 
			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        /> 
				</div>
	      	</CCol>
	    </CRow> 
		</CCard>
		<payment-receive-modal :showViewModal="showViewModal" :row="selected_data" @modalShowHide="modalShowHide" @updatePage="updatePage" :currentPage="currentPage" />
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';
import '../../style.css';
import paymentReceiveModal from './payment_receive.vue';
export default {
	mounted(){  
		this.getVendor();
		this.getDepot();
		this.getData();  
	},
	computed: {
		showUpdate() {
			return item => {
				if(item.status == 'unbilled') return true;

				if(item.status == 'billed' && this.has_role(['company admin'])) return true;

				return false;
				
			}
		}
	},
	data(){
		return{
			openReceivePaymentModal:false,
			isLoading: false,
			config,
			// // approveModal: false,
			// dispatchModal: false,
			// deliverModal: false,
			// completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [],  

			depotName: "", 
			fields: [
				{
					key: 'client_billing_no', 
					label: 'Billing No.'
				},
				{
					key: 'created_at_label', 
					label: 'Date Created'
				},
				{
					key: 'ref_no', 
					label: 'Ref No.'
				},
				{
					key: 'delivery_dates', 
					label: 'Delivery Dates'
				},
				{
					key: 'customer_id_label', 
					label: 'Customer'
				},
				{
					key: 'origin_id_label', 
					label: 'Origin'
				},  
				{
					key: 'commodity_id_label', 
					label: 'Commodity'
				},  
				{
					key: 'depot_id_label', 
					label: 'Depot'
				},
				{
					key: 'total_amount', 
					label: 'Amount'
				},   
				{
					key: 'status', 
					label: 'Status'
				},
				
				{
					key: 'action', 
					label: 'Action'
				}
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				client_billing_no: "",
	            customer_id: "",
	            origin_id: "",
				depot_id: "",
	            status: "",
	            ref_no: "", 
	            created_at_from: "",
	            created_at_to: "",
	            delivery_date_from: "",
	            delivery_date_to: "",
			},
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			},
			selected_data:[],
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect,paymentReceiveModal},
	methods: {
		getBadge (status) {
			return status === 'billed' ? 'success'
				: status === 'PARTIALLY PAID' ? 'warning'
         		: status === 'PAID' ? 'primary'
				: status === 'cancelled' ? 'danger' : 'info'
		},
		reloadData(){
			this.getData();  
		},
		updatePage(data){
			this.currentPage = data;
			this.getData();
		}, 
		 backToTable(){
	    	this.$router.push('/booking/'+this.$route.params.id+'/dr')
	    },
  

		// updatePageUnderReview(data){
		// 	this.currentPageUnderReview = data;
		// 	this.getDataUnderReview();
		// },   

		// // updatePageApproved(data){
		// // 	this.currentPageApproved = data;
		// // 	this.getDataApproved();
		// // },   

		// updatePageDeclined(data){
		// 	this.currentPageDeclined = data;
		// 	this.getDataDeclined();
		// },   

		// updatePageDispatched(data){
		// 	this.currentPageDispatched = data;
		// 	this.getDataDispatched();
		// },   

		// updatePageDelivered(data){
		// 	this.currentPageDelivered = data;
		// 	this.getDataDelivered();
		// },   

		// updatePageCompleted(data){
		// 	this.currentPageCompleted = data;
		// 	this.getDataCompleted();
		// },   

		// updatePageCancelled(data){
		// 	this.currentPageCancelled = data;
		// 	this.getDataCancelled();
		// },   

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 

	    getData(){
			this.isLoading=true;

	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	}
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	}  
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}  
			
			let delivery_date_from = '',delivery_date_to = '',created_at_from = '', created_at_to = '';
	    	if(this.filter.delivery_date_from != ""){ 
	    		delivery_date_from = moment(this.filter.delivery_date_from).startOf('day') / 1000;
	    	}

	    	if(this.filter.delivery_date_to != ""){ 
	    		delivery_date_to = moment(this.filter.delivery_date_to).startOf('day') / 1000;
	    		delivery_date_to += 86399;
	    	}

	    	if(this.filter.created_at_from != ""){ 
	    		created_at_from = moment(this.filter.created_at_from).startOf('day').format("yyyy-MM-DD HH:mm:ss"); 
	    	}

	    	if(this.filter.created_at_to != ""){ 
	    		created_at_to = moment(this.filter.created_at_to).endOf('day').format("yyyy-MM-DD HH:mm:ss"); 
	    	}

	    	axios.get(config.api_path+'/client_billing?client_billing_no='+this.filter.client_billing_no
			+'&customer_id='+customer_id
			+'&origin_id='+origin_id
			+'&depot_id='+depot_id
			+'&status='+this.filter.status
			+'&ref_no='+this.filter.ref_no
			+'&created_at_from='+created_at_from
			+'&created_at_to='+created_at_to
			+'&delivery_date_from='+delivery_date_from
			+'&delivery_date_to='+delivery_date_to+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{
					let booking = value.booking;
					let total_amount = 0;
					if(this.is_roadwise()){
							booking.forEach(element => {
								let amount = parseFloat(element.total_amount);
								let billable = element.billable;
								let vatable_amount = 0;
								if(billable) {
									amount += billable?.extra_drop ? parseFloat(billable.extra_drop) : 0;
									amount += billable?.toll_fee ? parseFloat(billable.toll_fee) : 0;
									
									vatable_amount += amount;

									amount += billable?.extra_helper ? parseFloat(billable.extra_helper) : 0;
									amount += billable?.other_expenses ? parseFloat(billable.other_expenses) : 0;
								}
								
								if(value.tax_type == 'vat') {
									total_amount += parseFloat((amount + (vatable_amount * 0.12)).toFixed(2))
								}
								else
									total_amount += amount;

									
							});
							
					}
					else if(this.is_marvel()){
							booking.forEach(element => {
								let amount = parseFloat(element.total_amount);
								
								let billable = element.billable;
								let vatable_amount = 0;
								if(billable) {
									
									amount += billable?.toll_fee ? parseFloat(billable.toll_fee) : 0;
									vatable_amount += amount;

								}
								
								if(value.tax_type == 'vat') {
									total_amount += parseFloat((amount + (vatable_amount * 0.12)).toFixed(2))
								}
								else
									total_amount += amount
							});
					}
					else {
						booking.forEach(element => {
								let amount = parseFloat(element.total_amount);
								let billable = element.billable;
								let vatable_amount = 0;
								if(billable) {
									
									// amount += billable?.toll_fee ? parseFloat(billable.toll_fee) : 0;
									vatable_amount += amount;

								}
								
								if(value.tax_type == 'vat') {
									total_amount += parseFloat((amount + (vatable_amount * 0.12)).toFixed(2))
								}
								else
									total_amount += amount
							});

					}
						
	    			if(value.booking_type_id){
	    				value.booking_type_id_label = value.booking_type.setting_name
	    			}
	    			else{
	    				value.booking_type_id_label = "-"
	    			}

	    			if(value.customer_id){
	    				value.customer_id_label = value.customer.customer_name
	    			}
	    			else{
	    				value.customer_id_label = "-"
	    			}

	    			if(value.depot){
	    				value.depot_id_label = value.depot.setting_name
	    			}
	    			else{
	    				value.depot_id_label = "-"
	    			}

	    			if(value.origin_id){
	    				value.origin_id_label = value.origin.setting_name
	    			}
	    			else{
	    				value.origin_id_label = "-"
	    			}

	    			if(value.commodity_id){
	    				value.commodity_id_label = value.commodity_type.setting_name
	    			}
	    			else{
	    				value.commodity_id_label = "-"
	    			}

	    			value.ref_no = value.ref_no ?? '-';
 
	    			value.created_at_label = moment(value.created_at).format('MM/DD/YYYY');
	    			value.delivery_dates = moment.unix(value.delivery_date_from).format('MM/DD/YYYY') +" - "+ moment.unix(value.delivery_date_to).format('MM/DD/YYYY')

					value.total_amount = total_amount.toFixed(2);
	    			return value;
	    		}); 
	    	})
			.catch(err => {
				// console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				// console.log(fin)
				this.isLoading = false;	
			})
	    },

	    
	    updateModal(item){ 
	    	// this.$router.push('/main/update_client_billing/'+item.id) 
			window.open('#/main/update_client_billing/'+item.id, '_blank');
	    },

	    viewModal(item){ 
	    	// this.$router.push('/main/view_client_billing/'+item.id) 
			window.open('#/main/view_client_billing/'+item.id, '_blank');
	    },
		// paymentReceiveModal(){
		// 	window.open('#/main/client_billing_payment_receive/'+item.id, '_blank');
		// },
	    deleteRow(item){
	    	Swal.fire({
				icon: 'warning',
				title: 'Are you sure you want to delete this billing?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/client_billing/'+item.id)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "Client Billing successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    }, 

	    getVendor(){

	    	axios.get(config.api_path+'/vendor?page=1&limit=10000000')
	    	.then(response => {
	    		this.vendorList = response.data;
	    		this.vendorList.data = this.vendorList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.vendor_name;
	    			return value;
	    		})
	    	})

	    },

		getDepot() {
			axios.get(config.api_path + '/reference/settings-list', {
				params: {
					setting_type: 'depot'
				}
			})
				.then(response => {
					this.depotList = response.data;
				})
		},

	    download(){ 
			// console.log(this.filter)
	    	var customer_id = this.filter.customer_id;
	    	if(customer_id == "all" || customer_id == null){
	    		customer_id = ""
	    	}
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "all" || origin_id == null){
	    		origin_id = ""
	    	}  

			let depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}  

			let delivery_date_from = '',delivery_date_to = '',created_at_from = '', created_at_to = '';
	    	if(this.filter.delivery_date_from != ""){ 
	    		delivery_date_from = moment(this.filter.delivery_date_from).startOf('day') / 1000;
	    	}

	    	if(this.filter.delivery_date_to != ""){ 
	    		delivery_date_to = moment(this.filter.delivery_date_to).startOf('day') / 1000;
	    		delivery_date_to += 86399;
	    	}

	    	if(this.filter.created_at_from != ""){ 
	    		created_at_from = moment(this.filter.created_at_from).startOf('day').format("yyyy-MM-DD HH:mm:ss"); 
	    	}

	    	if(this.filter.created_at_to != ""){ 
	    		created_at_to = moment(this.filter.created_at_to).endOf('day').format("yyyy-MM-DD HH:mm:ss"); 
	    	}
	    	// if(this.filter.delivery_date_from != ""){ 
	    	// 	this.filter.delivery_date_from = moment(this.filter.delivery_date_from).startOf('day') / 1000;
	    	// }

	    	// if(this.filter.delivery_date_to != ""){ 
	    	// 	this.filter.delivery_date_to = moment(this.filter.delivery_date_to).startOf('day') / 1000;
	    	// 	this.filter.delivery_date_to += 86399;
	    	// }

	    	// if(this.filter.created_at_from != ""){ 
	    	// 	this.filter.created_at_from = moment(this.filter.created_at_from).startOf('day').format("yyyy-MM-DD HH:mm:ss"); 
	    	// }

	    	// if(this.filter.created_at_to != ""){ 
	    	// 	this.filter.created_at_to = moment(this.filter.created_at_to).endOf('day').format("yyyy-MM-DD HH:mm:ss"); 
	    	// }
			this.$showLoading(true)
	    	axios.get(config.api_path+'/reports/client_billing?client_billing_no='
				+this.filter.client_billing_no
				+'&customer_id='+customer_id
				+'&origin_id='+origin_id
				+'&status='+this.filter.status
				+'&ref_no='+this.filter.ref_no
				+'&created_at_from='+created_at_from
				+'&created_at_to='+created_at_to
				+'&delivery_date_from='+delivery_date_from
				+'&delivery_date_to='+delivery_date_to
				+'&page='+this.currentPage
				+'&depot_id='+depot_id
				+'&limit=20')
	    	.then(response=>{
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

	    		setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
	    	})
			.catch(err => {
				this.$showLoading(false)
			})
	    },
		modalShowHide(open_close, data = []){
			this.selected_data = data;
			this.showViewModal = open_close;
		},
		labelStatusColor(data){
			const current_date = moment().format('MM/DD/YYYY'); //new Date();
			const data_date = moment(data.term_due_date).format('MM/DD/YYYY');
			if(moment(current_date).isSameOrBefore(moment(data_date))){
				if(data.status=='billed' || data.status== 'unbilled'){
					return '#0058b1';
				}
				else if(data.status=='PARTIALLY PAID'){
					return '#3b3939';
				}
				else{
					return '';
				}
			}
			else{
				if(data.status=='PAID' || data.status=='PARTIALLY PAID'){
					return '';
				}
				return 'red';
			}
		},
		labelStatus(data){
			if(!data.term_due_date || data.term_due_date == ''){
				if(data.status=='PAID'){
					return '';
				}
				else if(data.status=='PARTIALLY PAID'){
					let total_payment = 0;
					data.payment_received.forEach(element => {
						total_payment +=parseFloat(element.amount);
					});
					console.log(total_payment+' '+ data.total_amount);
					return this.$options.filters.CurrencyFormatWithSign(parseFloat(data.total_amount)-total_payment)+' due';
				}
				else{
					return '';
				}
			}
			const current_date = moment().format('MM/DD/YYYY'); //new Date();
			const data_date = moment(data.term_due_date).format('MM/DD/YYYY');
			if(moment(current_date).isSameOrBefore(moment(data_date))){
				if(data.status=='PAID'){
					return '';
				}
				else if(data.status=='PARTIALLY PAID'){
					let total_payment = 0;
					data.payment_received.forEach(element => {
						total_payment +=parseFloat(element.amount);
					});
					return this.$options.filters.CurrencyFormatWithSign(parseFloat(data.total_amount)-total_payment)+' due';
				}
				else if(data.status == 'billed' || data.status == 'unbilled'){
					return 'Due on ' + data.term_due_date;
				}
				else{
					return '3';
				}
			}
			else{
				if(data.status=='PAID'){
					return '';
				}
				else if(data.status=='PARTIALLY PAID'){
					let total_payment = 0;
					data.payment_received.forEach(element => {
						total_payment +=parseFloat(element.amount);
					});
					return this.$options.filters.CurrencyFormatWithSign(parseFloat(data.total_amount)-total_payment)+' due';
				}
				else{
					return 'Overdue ' + data.term_due_date;
				}
			}
		},
		sendReminder(item) {
			Swal.fire({
				title: 'Sending email',
				html: 'Please wait.',
				allowOutsideClick:false,
				allowEscapeKey:false
			});
			Swal.showLoading();
			axios.post(config.api_path + '/client-billing-reminder/' + item.id)
			.then(response => {
				Swal.fire({
					title: 'Success!',
					text: 'Successfully notified ' + response.data.message,
					icon: 'success',
				})
			}).catch(error => {
               
                let errors = error.response.data.errors;
                if(errors.no_email){
					Swal.close();
					Swal.fire({
						title: 'No Email',
						text: 'The customer has no email address.',
						icon: 'error',
					})
                }
                this.is_loading = false;
            });
		}
	    // deleteItem(item){
	    // 	axios.delete(config.api_path+'/client_billing/'+item.id)
	    // 	.then(response => {
	    // 		Swal.fire({
		// 			title: 'Success!',
		// 			text: "Client billing successfully deleted",
		// 			icon: 'success', 
		// 		})
		// 		this.getData();	
	    // 	})
	    // }
 	}
}
</script>

<style type="css" scope>
	.label-status{
		display: block;
		font-size: 9px;
		font-weight: 800;
	}
</style>
